<template>
  <div id="app" :class="['app ' + $route.name, {isAuth: 'isAuth'}]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <Header />
        <router-view :isAuth="isAuth" @openSignInModal="openSignInModal" @setFiatToCrypto="setFiatToCrypto" :profileData="profileData" :paymenMethods="settings.payment_methods" @setAppAmounts="setAppAmounts" @setPaymentMethod="setPaymentMethod" :currencies="currencies" :crypto="crypto" :rates="rates" @setCheckoutData="setCheckoutData" @setWallet="setWallet" @logout="logout" />
        <Footer/>
        <transition name="fade">
          <SignInModal v-if="signInModalIsVisible" @closeSignInModal="closeSignInModal" @login="login"/>
        </transition>
        <transition name="fade">
          <RecoverModal v-if="recoverModalIsVisible" @closeRecover="closeRecover" @forgotPass="forgotPass" />
        </transition>
        <transition name="fade">
          <RegisterModal v-if="registerModalIsVisible" @closeRegister="closeRegister" @registration="registration" />
        </transition>
        <transition name="fade">
          <PersonalInfoModal v-if="personalInfoModalIsVisible"  @closePersonalInfoModal="closePersonalInfoModal" @orderSubmit="orderSubmit" @setPersonalInfo="setPersonalInfo"/>
        </transition>
        <transition name="fade">
          <ContactForm v-if="contactModalIsVisible" />
        </transition>
        <transition name="fade">
          <EditDataModal v-if="editDataModalIsVisible" :profileData="profileData" @submitEditData="submitEditData" />
        </transition>
        <transition name="fade">
          <EditPassModal v-if="editPassModalIsVisible" @submitPassData="submitPassData" />
        </transition>
        <cookie-modal></cookie-modal>
      </div>
    </transition>
    <!-- <Chatbot/> -->
  </div>
</template>

<script>
import CookieModal from './components/CookieModal.vue';
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import SignInModal from './components/SignInModal.vue'
import RecoverModal from './components/RecoverModal.vue'
import RegisterModal from './components/RegisterModal.vue'
import PersonalInfoModal from './components/PersonalInfoModal.vue'
// import Chatbot from "@/components/BotPressChat.vue";
import ContactForm from './components/ContactForm.vue'
import EditDataModal from './components/EditDataModal.vue'
import EditPassModal from './components/EditPassModal.vue'

export default {
  name: 'app',
  components: {
    // Chatbot,
    Header,
    Footer,
    SignInModal,
    RecoverModal,
    RegisterModal,
    PersonalInfoModal,
    CookieModal,
    ContactForm,
    EditDataModal,
    EditPassModal
  },
  data: function() {
    return {
      pageLoaded: false,
      headerNav: [
        {
          title: 'Exchange',
          path: '/exchange-crypto'
        },
        {
          title: 'Buy Crypto',
          path: '/buy-crypto'
        },
        {
          title: 'Sell Crypto',
          path: '/sell-crypto'
        },
        {
          title: 'Features',
          path: '/features'
          
        },
        {
          title: 'Support',
          modal: 'support'
        }
      ],
      footerNav1: [
        {
          title: 'Sell Crypto',
          path: '/sell-crypto'
        },
        {
          title: 'Buy Crypto',
          path: '/buy-crypto'
        }
      ],
      footerNav2: [
        {
          title: 'FAQ',
          path: '/faq'
        },
        {
          title: 'Contact Us',
          modal: 'contact'
        }
      ],
      signInModalIsVisible: false,
      registerModalIsVisible: false,
      recoverModalIsVisible: false,
      error: '',
      isAuth: false,
      successMessage: '',
      currencies: {},
      crypto: {},
      rates: [],
      wallets: [],
      orderBuyHistory: [],
      orderSellHistory: [],
      giveAmount: 0,
      takeAmount: 0,
      takeCurrency: {},
      giveCurrency: '',
      paymentMethod: '',
      fiatToCrypto: true,
      personalInfoModalIsVisible: false,
      wallet: '',
      phone: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      birthDate: '',
      name: '',
      surname: '',
      email: '',
      faq: [],
      textPageList: [],
      socialLinks: [],
      hostname: '',
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      checkoutSuccess: false,
      companyEmail: '',
      companyPhone: '',
      billingDescriptor: '',
      minOrderPriceFiatToCrypto: null,
      minOrderPriceCryptoToFiat: null,
      legalInfo: '',
      settings: '',
      buyReport: null,
      sellReport: null,
      contactModalIsVisible: false,
      profileData: {},
      countryOptions: [],
      countryOptionsId: [],
      countryOptionsIso: [],
      emailVerified: false,
      editDataModalIsVisible: false,
      editPassModalIsVisible: false,
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    setFiatToCrypto(state) {
      this.fiatToCrypto = state;
    },
    setAppAmounts(amounts) {
      this.giveCurrency = amounts.fiatCurr;
      this.takeCurrency = amounts.cryptoCurr;
      this.takeAmount = amounts.crypto;
      this.giveAmount = amounts.fiat;
      if (amounts.type == 'sell') {
        this.fiatToCrypto = false;
        this.$router.push({path: '/order-details/sell'})
      } else {
        this.fiatToCrypto = true;
        this.$router.push({path: '/order-details/buy'})
      }
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.profileData = res.data;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
      this.getCounties();
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
          this.countryOptionsIso = res.data.payload.map(obj => obj.iso);
      })
      .catch(() => {
      })
    },
     checkAuthStatus() {
      return this.$http.get(process.env.VUE_APP_API + 'is-auth')
      .then((res) => {
        this.isAuth = res.data.authenticated;
        this.emailVerified = res.data.emailVerified;
        localStorage.setItem('isAuthenticated', this.isAuth.toString());
        localStorage.setItem('emailVerified', this.emailVerified.toString())

        if (!this.isAuth && this.$route.name === 'OrderRequisites') {
          this.$router.push({ name: 'Home', query: { redirect: this.$route.path } }).catch(() => {});
        } else if (this.isAuth && !this.emailVerified) {
          this.$router.push({ name: 'EmailVerificationNotice' });
        }
        
        if (res.data.authenticated == true) {
          this.getProfile();
          this.getOrderHistory();
        } else {
          this.getCounties();
        }
        if (!res.data.emailVerified ) {
          this.$http.get(process.env.VUE_APP_API + 'user/profile-unverified').then((res) => {
            this.profileData = res.data;
          })
          .catch(() => {
          });
        }
      })
      .catch(() => {
          this.$http.get(process.env.VUE_APP_API + 'user/profile-unverified').then((res) => {
            this.profileData = res.data;
          })
          .catch(() => {
          });
      });
    },
    isEmailVerified() {
      return JSON.parse(localStorage.getItem("emailVerified") || "false");
    },
    setPaymentMethod(type) {
      this.paymentMethod = type;
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    clearError() {
      this.error = '';
    },
  
    openContactModal() {
      this.contactModalIsVisible = true;
    },
    closeContactModal() {
      this.contactModalIsVisible = false;
      this.clearError();
    },

    openSignInModal() {
      this.registerModalIsVisible = false;
      this.recoverModalIsVisible = false;
      this.signInModalIsVisible = true;
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
      this.clearError();
    },
    openRecover() {
      this.signInModalIsVisible = false;
      this.registerModalIsVisible = false;
      this.recoverModalIsVisible = true;
    },
    closeRecover() {
      this.recoverModalIsVisible = false;
      this.clearError();
    },
    openRegister() {
      this.signInModalIsVisible = false;
      this.recoverModalIsVisible = false;
      this.registerModalIsVisible = true;
    },
    closeRegister() {
      this.registerModalIsVisible = false;
      this.clearError();
    },
    login(data) {
      let self = this;
      self.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          self.isAuth = true;
          localStorage.setItem('isAuthenticated', true.toString());
          localStorage.setItem('authToken', res.data.access_token);
          localStorage.setItem('emailVerified', !!res.data.email_verified);
          self.clearError();
          self.closeSignInModal();
          self.checkAuthStatus();
          if (this.$route.query['redirect']) {
            self.$router.push({ path: self.$route.query['redirect'] })
          } else {
            self.$router.push({ path: '/buy-crypto' });
          }
        } else {
          self.error = res.data.message
        }
      })
      .catch((res) => {
        self.error = res.response.data.message;
        this.isAuth = false;
        localStorage.setItem('isAuthenticated', false.toString());
        localStorage.setItem('emailVerified', false.toString());
        localStorage.removeItem('authToken');

      })
    },
    submitEditData(data) {
      let self = this;
      self.$http.put(process.env.VUE_APP_API + 'user/profile', data)
      .then((res) => {
        if (res.data.status == "OK") {
          self.clearError();
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          self.error = res.data.message
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.error = firstErrors[key];
            }
          }
        } else {
          this.error = res.response.data.message;
        }
        
      })
    },
    submitPassData(data) {
      let self = this;
      self.$http.post(process.env.VUE_APP_API + 'user/change-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          self.clearError();
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          self.error = res.data.message
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.error = firstErrors[key];
            }
          }
        } else {
          this.error = res.response.data.message;
        }
      })
    },
    registration(data) {
      let self = this;
      self.$http.post(process.env.VUE_APP_API + 'register', data)
      .then((res) => {
        if (res.data.status == "OK") {
          self.clearError();
          self.isAuth = true;
          localStorage.setItem('isAuthenticated', true.toString());
          localStorage.setItem('authToken', res.data.access_token);
          localStorage.setItem('emailVerified', false);

          self.checkAuthStatus();
          self.closeRegister();

          if (this.$route.query['redirect']) {
            self.$router.push({path: self.$route.query['redirect']})
          } else {
            self.$router.push({path: '/profile'});
          }
        } else {
          self.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((error) => {
        this.error = error.response.data.message
      })
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
      .then(() => {
        this.isAuth = false;
        localStorage.removeItem('authToken');
        localStorage.setItem('isAuthenticated', false.toString());
        localStorage.setItem('emailVerified', false.toString());

        this.$router.push({path: '/'});
        this.openSignInModal();
      })
      .catch(() => {
        
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'orders/history')
      .then((res) => {
        this.orderBuyHistory = res.data.payload;
      })
      .catch(() => {
      })
      this.$http.get(process.env.VUE_APP_API + 'sell/history')
      .then((res) => {
        this.orderSellHistory = res.data.payload;
      })
      .catch(() => {
      })
    },
    async getCurrencies() {
      return this.$http.get(process.env.VUE_APP_API + 'currencies-data')
      .then((res) => {
        this.currencies = res.data.currencies;
        this.crypto = res.data.crypto;
        this.rates = res.data.rates;
        this.wallets = res.data.wallets;
      })
      .catch(() => {
       
      })
    },
    setCheckoutData(giveAmount, takeAmount, takeCurrency, giveCurrency, paymentMethod, fiatToCrypto) {
      this.giveAmount = giveAmount;
      this.takeAmount = takeAmount;
      this.takeCurrency = takeCurrency;
      this.giveCurrency = giveCurrency;
      this.paymentMethod = paymentMethod;
      this.fiatToCrypto = fiatToCrypto
    },
    orderSubmit() {
      let data = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "country": this.countryId,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode,
        "paymentType": this.paymentMethod,
        "source_asset": this.fiatToCrypto ? this.takeCurrency.currency : this.takeCurrency.crypto_currency,
        "dest_asset": this.fiatToCrypto ? this.takeCurrency.crypto_currency : this.takeCurrency.currency,
        "amount": this.fiatToCrypto ? this.giveAmount : this.takeAmount,
        "exchange_rate": this.takeCurrency.price,
        "wallet": this.wallet
      }

      if (!this.fiatToCrypto) {
        data['birth_date'] = this.birthDate;
      }
      
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
          if (res.data.redirect_url) {
            window.location = res.data.redirect_url;
          } else {
            this.changeCheckoutSuccess(true);
            this.closePersonalInfoModal();
            // this.$router.push({path: '/'});
          }
      })
      .catch((res) => {
        this.error = res.response.data.message;
      })
    },
    openEditDataModal() {
      this.editDataModalIsVisible = true;
    },
    closeEditDataModal() {
      this.editDataModalIsVisible = false;
      this.clearError();
    },
    openPassDataModal() {
      this.editPassModalIsVisible = true;
    },
    closePassDataModal() {
      this.editPassModalIsVisible = false;
      this.clearError();
    },
    openPersonalInfoModal() {
      this.personalInfoModalIsVisible = true;
    },
    closePersonalInfoModal() {
      this.personalInfoModalIsVisible = false;
      this.clearError();
    },
    setPersonalInfo( email, name, surname, phone, postCode, countryId, city, address, birthDate, wallet) {
      this.email = email;
      this.name = name;
      this.surname = surname;
      this.phone = phone;
      this.countryId = countryId;
      this.city = city;
      this.address = address;
      this.postCode = postCode;
      this.birthDate = birthDate;
      this.wallet = wallet;
    },
    setWallet(wallet) {
      this.wallet = wallet;
    },
    goToPrivacy() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_privacy) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);
        } 
      }
    },
    goToTerms() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_terms) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);
        } 
      }
    },
    goToAllowedCountries() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_countries) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);
        } 
      }
    },
    goToPage(item) {
      this.$router.push({path: '/pages/' + item.id, params: {data: item.id}})
      this.getTextPageContent(item.id);
    },
    goToCookiesPage() {
      this.$router.push({path: '/pages/' + 5, params: {data: 7}})
      this.getTextPageContent(5);
    },
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
      .then((res) => {
        this.textPageTitle = res.data.payload.title;
        this.textPageContent = res.data.payload.content;
        this.textPageHeader = res.data.payload.header;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal()
        }
      })
    },
    getTextPages() {
      return this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch(() => {
         
      })
    },
    getFaq() {
      return this.$http.get(process.env.VUE_APP_API + 'faq')
      .then((res) => {
          this.faq = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    toExchange(giveAmount, takeAmount, chosenCrypto) {
      this.giveAmount = giveAmount;
      this.takeAmount = takeAmount;
      this.takeCurrency = chosenCrypto;
      this.$router.push({path: '/buy-crypto'});
    },
    getGoogleTagManagerSnippet() {
      this.$http.get(process.env.VUE_APP_API + 'settings')
      .then((res) => {
        const payload = res.data.payload;
        this.settings = res.data.payload;
        this.legalInfo = res.data.payload.legal_info;
        this.sellReport = res.data.payload.sell_report;
        this.buyReport = res.data.payload.buy_report;
        this.companyEmail = res.data.payload.support_email;
        this.companyPhone = res.data.payload.support_phone;
        this.billingDescriptor = res.data.payload.billing_descriptor;
        this.minOrderPriceFiatToCrypto = payload.min_order_fiat_to_crypto ? parseFloat(payload.min_order_fiat_to_crypto) : null;
        this.minOrderPriceCryptoToFiat = payload.min_order_crypto_to_fiat ? parseFloat(payload.min_order_crypto_to_fiat) : null;

        const snippet = res.data.payload.google_tag;
        const container = document.createElement('div');
        container.innerHTML = snippet;

        Array.from(container.childNodes)?.filter((element) => element?.tagName === 'SCRIPT')?.forEach(element => {document.head.appendChild(element);});
      })
      .catch((error) => {
        console.error("Error fetching Google Tag Manager snippet:", error);
      });
    },
    getSocialLinks() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
          .then(({data}) => {
            this.socialLinks = data.payload;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    changeCheckoutSuccess(value) {
      this.checkoutSuccess = value;
    }
  },
  created() {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      this.isAuth = true;
    }
  },
  mounted: async function() {
    this.getCounties();
    this.getCurrencies();
    this.getGoogleTagManagerSnippet();
    this.getSocialLinks();
    // let self = this;
    this.currency = process.env.VUE_APP_CURRENCY;
    this.pageLoaded = true;

    this.hostname = this.getDomainName(window.location.hostname)
    this.getFaq();
    await Promise.all([this.getTextPages(), this.checkAuthStatus()]);
  },
  computed: {
    
  }
}
</script>
