<template>
  <main v-if="isStaticPage" class="main text-page">
    <div class="section text-section">
      <transition name="fade">
        <div class="wrapper" v-if="$parent.textPageTitle">
          <div class="title">{{$parent.textPageTitle}}</div>
          <div class="desc" v-html="$parent.textPageHeader"></div>
          <div class="desc" v-html="$parent.textPageContent"></div>
        </div>
      </transition>
    </div>
  </main>
  <main v-else class="main page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">404</div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "PageNotFound",
  data() {
    return {
      isStaticPage: false
    };
  },
  async mounted() {
    await this.checkStaticPage(this.$route.path);
  },
  watch: {
    async $route() {
      await this.checkStaticPage(this.$route.path);
    }
  },
  methods: {
    async checkStaticPage(path) {
      // Используем textPageList из родительского компонента
      const staticPage = this.$parent.textPageList.find(
          (page) => `${page.url}` === path || `/${page.url}` === path
      );

      if (staticPage) {
        this.isStaticPage = true;
        await this.$parent.getTextPageContent(staticPage.id);
      } else {
        this.isStaticPage = false;
      }
    },
  },
};
</script>

<style>
/* Добавьте стили, если нужно */
</style>
